<template>
  <div style="background-color: #FFFFFF;">
    <el-tabs v-model="activeName">
      <el-tab-pane label="我的订单" name="order">
      </el-tab-pane>
    </el-tabs>
    <el-row style="margin: 20px;">
      <el-col :span="24">
        <div class="il-list">
          <el-table :data="tableData" border :header-cell-style="headerColor">
            <el-table-column prop="orderNum" label="订单编号" width="200" align="center">
            </el-table-column>
            <el-table-column prop="planName" label="类型" align="center">
            </el-table-column>
            <el-table-column prop="payablePrice" label="订单金额" align="center">
            </el-table-column>
            <el-table-column prop="orderPrice" label="实付金额" align="center">
            </el-table-column>
            <el-table-column prop="payType" label="支付方式" align="center">
              <template #default="scope">
                <p v-if="scope.row.payType == '00040001'">不限制支付方式</p>
                <p v-if="scope.row.payType == '00040003'">线下支付</p>
                <p v-if="scope.row.payType == '00040004'">平台赠送</p>
                <p v-if="scope.row.payType == '00040005'">微信H5支付</p>
                <p v-if="scope.row.payType == '00040002'">微信支付</p>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="订单时间" align="center">
            </el-table-column>
            <el-table-column prop="payStatus" label="支付状态" align="center">
              <template #default="scope">
                <p v-if="scope.row.payStatus == 1">已支付</p>
                <p v-if="scope.row.payStatus == 0">未支付</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px" align="center">
              <template #default="scope">
<!--                <el-link style="color: blue;" v-if="scope.row.blBillRecordId == null"-->
<!--                         @click="handlerInvoice(scope.$index, scope.row)">去开票-->
<!--                </el-link>-->
                <router-link class="btn-hover-foucs" v-if="scope.row.blBillRecordId == null"
                         to="./invoice-list">去开票
                </router-link>
                <p v-if="scope.row.blBillRecordId != null">已开票</p>
              </template>
            </el-table-column>
            <template #empty style="width: 100%;">
              <el-empty :image-size="200"></el-empty>
            </template>
          </el-table>
        </div>
        <div class="o-page">
          <el-pagination
            :hide-on-single-page="true"
            :pager-count="page.pagerCount"
            background
            layout="prev, pager, next"
            @current-change="currentChange"
            :total="page.total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import { getOrdersByUserId } from '@/api/user.js'

export default {
  name: 'order',
  data () {
    return {
      tableData: null,
      startTime: '',
      endTime: '',
      page: {
        pagerCount: 7,
        currentPage: 1,
        total: 0
      }
    }
  },
  mounted () {
    this.loadData(1)
  },
  methods: {
    headerColor: function ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    // 翻页时触发
    currentChange (currentPage) {
      console.log('当前页: ' + currentPage)
      this.currentPage = currentPage
      this.loadData(currentPage)
    },
    loadData (currentPage) {
      const params = {
        userId: JSON.parse(this.$store.getters.userInfo).userId,
        pageNum: currentPage,
        pageSize: this.page.pagerCount
      }
      getOrdersByUserId(params).then(result => {
        if (result.code === 0) {
          console.log(result.data)
          this.page.total = result.data.total
          this.tableData = result.data.list
        } else {
          ElMessage.error({
            message: result.msg,
            offset: 60
          })
        }
      }).catch(error => {
        ElMessage.error({
          message: error,
          offset: 60
        })
      })
    },
    handlerInvoice (index, row) {
      const that = this
      that.$router.push({ path: '/invoice-list' })
    }
  }
}
</script>

<style scoped>
/deep/ .el-tabs__item {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  width: 120px;
}

.o-page {
  display: flex;
  justify-content: flex-end;
  margin: 20px auto;
}
.btn-hover-foucs {
  color: #5D6FE9;
  cursor: pointer;
  text-decoration: none;
}
.btn-hover-foucs:hover {
  font-weight: bold;
}
/deep/ .el-tabs__item{
  height: 55px;
  line-height: 55px;
}
</style>
